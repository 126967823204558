exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-first-blog-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kingkeem/Desktop/Projects/gatsby-portfolio/akeem-dainty-portfolio/blog/first-blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-first-blog-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-second-blog-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kingkeem/Desktop/Projects/gatsby-portfolio/akeem-dainty-portfolio/blog/second-blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-second-blog-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-third-blog-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kingkeem/Desktop/Projects/gatsby-portfolio/akeem-dainty-portfolio/blog/third-blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-blog-third-blog-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-projects-second-project-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kingkeem/Desktop/Projects/gatsby-portfolio/akeem-dainty-portfolio/projects/second-project/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-users-kingkeem-desktop-projects-gatsby-portfolio-akeem-dainty-portfolio-projects-second-project-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

